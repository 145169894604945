import { useAuth } from "../../auth/use-auth";
import Header from "./Header";

const AuthHeader = () => {
  let auth = useAuth();
  return auth.userType && auth.userType !== "vizitor" ? (
    <Header userIsLoggedIn={true} />
  ) : (
    <Header userIsLoggedIn={false} />
  );
};

export default AuthHeader;
