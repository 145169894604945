let environment = {
  frontendURL:
    process.env.REACT_APP_DEV === "true"
      ? "https://dev.amont.studio:4546"
      : "https://revomed.ru",
  backendURL:
    process.env.REACT_APP_DEV === "true"
      ? "https://dev.amont.studio"
      : "https://api.revomed.ru",
  capchaKey:
    process.env.REACT_APP_DEV === "true"
      ? "6LfSK7kkAAAAACLWST-IlQIFitchxod3r4hjTAnY"
      : "6LfqRLokAAAAAOrSpJMCpxvqYrAryeKRObMfGL-N",
};
export default environment;
