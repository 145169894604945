import instance from "../index";

class AuthAPI {
  static async loginSeller(data) {
    let res = await instance.post("/auth/sellers", data);
    return await res.data;
  }

  static async loginClient(data) {
    let res = await instance.post("/auth/clients", data);
    return await res.data;
  }

  static async logout() {
    let res = await instance.post("/logout");
    return await res.data;
  }
}

export default AuthAPI;
