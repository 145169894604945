import axios from "axios";

import ENVIRONMENT from "../utils/ENVIRONMENT";

const axiosInstance = axios.create({
  baseURL: ENVIRONMENT.backendURL + "/",
  responseType: "json",
  withCredentials: "true",
  credentials: "include",
  cacheControl: "no-store",
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    // Проверяем, если ответ успешный, но содержит сообщение о неверном токене
    if (
      response.data &&
      response.data.success === false &&
      response.data.message === "Вы не авторизованы"
    ) {
      const originaRequest = response.config;
      if (!originaRequest._isRetry) {
        originaRequest._isRetry = true;
        try {
          const refreshResponse = await axios.post(
            `${ENVIRONMENT.backendURL}/refresh-tokens`,
            {},
            { withCredentials: "true", credentials: "include" }
          );
          if (refreshResponse.data.accessToken) {
            localStorage.setItem(
              "accessToken",
              refreshResponse.data.accessToken
            );
          } else {
            localStorage.removeItem("accessToken");
          }
          return axiosInstance.request(originaRequest);
        } catch (e) {
          return Promise.reject(
            (e.response && e.response.data) || "Something went wrong"
          );
        }
      }
    }
    return response;
  },
  (error) => error
);

export default axiosInstance;
