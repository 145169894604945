import { useEffect, useState } from "react";
import { AuthContext } from "./auth-context";
import UsersAPI from "../api/services/users";
import AuthAPI from "../api/services/auth";

function AuthProvider({ children }) {
  let [userData, setUserData] = useState({
    userType: null,
    userId: null,
    userInfo: null,
  });

  const getMe = async () => {
    const data = await UsersAPI.getMe();
    if (data?.success) {
      if (data.type === "vizitor") {
        localStorage.removeItem("accessToken");
      }
      setUserData({
        userType: data.type,
        userId: data.id,
        userInfo: data.info,
      });
    } else {
      setUserData({ userType: "vizitor", userId: null, userInfo: null });
      localStorage.removeItem("accessToken");
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  let auth = {
    userType: userData.userType,
    userId: userData.userId,
    userInfo: userData.userInfo,
    signin: (cb) => {
      cb();
      getMe();
    },
    signout: (cb) => {
      setUserData({ userType: "vizitor", userId: null, userInfo: null });
      localStorage.removeItem("accessToken");
      AuthAPI.logout();
    },
    reconfirm: async (cb) => {
      await getMe();
      cb();
    },
  };

  if (
    userData.userType &&
    (userData.userId !== null || userData.userType === "vizitor")
  )
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  else return <div className="main-loader"></div>;
}

export default AuthProvider;
