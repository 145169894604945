import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getCookie } from "./utils";
import { useAuth } from "./use-auth";

function AuthRouteGuard() {
  let auth = useAuth();
  let location = useLocation();
  let cookieMatch = getCookie("bookingInfo");

  useEffect(() => {
    if (
      location.pathname === "/write/review/:link" &&
      auth.userType === "vizitor"
    ) {
      document.cookie = "linkReview=" + location.pathname;
    }
  }, [location.pathname, auth.userType]);

  if (!auth.userType || auth.userType === "vizitor") {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (auth.userType === "client" && !auth.userInfo.name) {
    return <Navigate to="/meeting" state={{ from: location }} replace />;
  }

  if (auth.userType === "client" && !auth.userInfo.name) {
    return <Navigate to="/meeting" state={{ from: location }} replace />;
  }

  if (cookieMatch) {
    return (
      <Navigate to="/continue-booking" state={{ from: location }} replace />
    );
  }
  return <Outlet />;
}

export default AuthRouteGuard;
