import { DateTime } from "luxon";
import instance from "../index";

class UsersAPI {
  static async getMe() {
    let data = { timezone: DateTime.now().zoneName };
    let res = await instance.get("/users", { params: data });
    if (res.data) {
      if (res.data.accessToken) {
        localStorage.setItem("accessToken", res.data.accessToken);
      }
    }
    return await res.data;
  }
}

export default UsersAPI;
